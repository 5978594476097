@font-face {
  font-family: 'Forza';
    font-weight: 325;
    src: local('Forza'), url(./fonts/Forza-Book.ttf) format('truetype');
}
@font-face {
    font-family: 'Forza';
    font-weight: 350;
    src: local('Forza'), url(./fonts/Forza-Medium.ttf) format('truetype');
}
@font-face {
    font-family: 'Forza';
    font-weight: 400;
    src: local('Forza'), url(./fonts/Forza-Bold.ttf) format('truetype');
}
@font-face {
    font-family: 'Forza';
    font-weight: 500;
    src: local('Forza'), url(./fonts/Forza-Bold.ttf) format('truetype');
}
@font-face {
    font-family: 'Forza';
    font-weight: 700;
  src: local('Forza'), url(./fonts/Forza-Bold.ttf) format('truetype');
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: Forza, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url('/public/assets/newBackground.jpg') no-repeat center center fixed;
  background-size: cover;
  height: 100%;
    color:white;
}
button {
    font-family: 'Forza';
}

@media (max-width: 768px) {
  body{
  background-color: black;
  background-size: auto;
}
}

body::-webkit-scrollbar-thumb {
  background-color: rgb(255, 138, 3);
  border-radius: 41px;
}
body::-webkit-scrollbar-track {
  background-color: rgb(61, 74, 87);
  border-radius: 41px;
}
body::backdrop {
  background-color: rgb(7, 17, 27);
}
body::-webkit-scrollbar {
  width: 5px;
}

.ReactModal__Body--open {
    overflow: hidden;
}
